export default {
  name: 'ParceiroMixin',
  inject: {
    erplayout: {
      default () {
        console.error('Main needs to be child of ErpLayout')
      }
    }
  },
  mounted() {
    window.setTimeout(() => {
      this.erplayout.erpheader.setMenuModuleToggle(true)
    }, 10)
    document.body.classList.add('parceiro-module-body')
    this.erplayout.erpheader.menu.setActiveMenu('gestao')
  },
  destroyed() {
    document.body.classList.remove('parceiro-module-body')
    // document.querySelector('.u-erp-layout-container').classList.remove('bg-white')
  },
  beforeDestroy() {
    this.erplayout.erpheader.setMenuModuleToggle(false)
  }
}
